.ai-button {
  transition: all 0.3s ease;
}

.ai-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ai-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.ai-button .fa-robot {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

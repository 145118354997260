img {
  transition: transform 0.5s ease;
}

img:hover {
  cursor: pointer;
}

.custom-border {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-img {
  width: 100%;
  height: auto;
  max-height: 600px; /* Increased max height for larger image */
  border-radius: 5px;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.step-container {
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-image {
  max-width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 15px;
}

.step-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.step-container {
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
}

.step-container.expanded .step-image {
  transform: scale(1.8); /* Adjust the scale as needed */
  transition: transform 0.3s ease;
}

.expanded .misc-image {
  transform: scale(1.8); /* Adjust the scale as needed */
  transition: transform 0.3s ease;
}

.step-container.expanded {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.landing-container {
  border: 1px solid #e0e0e0; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the container */
  background-color: #f8f9fa; /* Slightly different background for better contrast */
}

.img-container img {
  transition: transform 0.5s ease;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.demo-video {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-body {
  padding: 2rem;
}

.card-subtitle {
  font-size: 1.2rem;
}

form .form-group {
  margin-bottom: 1.5rem;
}

form .form-control {
  border-radius: 10px;
  border: 1px solid #ced4da;
}

.autocomplete-dropdown-container {
  position: relative;
}

.suggestion-item {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  padding: 10px;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.button-outline-primary {
  display: flex;
  align-items: center;
}

.button-outline-primary .ms-2 {
  margin-left: 8px;
}

.highlight-checkbox {
  background-color: #e9ecef;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 15px;
}

.highlight-checkbox .form-check-label {
  font-weight: bold;
  color: #495057;
}

.highlight-checkbox .form-check-input {
  transform: scale(1.5);
}

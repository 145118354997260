.card {
  display: flex;
  flex-direction: column;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text {
  flex-grow: 1;
}

button {
  margin-top: 10px;
}

.card-text {
  /* Copy necessary styles from Card.Text */
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  margin-top: 0;
  margin-bottom: 1rem;
}

.disabled-card {
  opacity: 0.75;
  pointer-events: none;
}

.btn-link {
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
  color: #0056b3;
}
